<template>
  <div class="search">
    <Header ref="Header"></Header>
    <div class="s_from">
      <Form class="Form" ref="Form"></Form>
    </div>
    <div class="data_number">
      3Q 为您找到 <span> {{ sum }} </span>条数据
    </div>
    <div class="data_view">
      <div class="close_data" v-bind:style="close_data">暂无数据</div>
      <div class="data" v-for="(item,index) in data" :key="index">
        <div class="information">
            <h3 v-html="item.Name" @click="Researchs(item)"></h3>
            <span class="school" v-html="item.School"></span>
            <span class="Category" v-html="item.Category"></span>
          </div>
          <keep-alive>
            <p class="Research" v-html="item.Research" @click="Researchs(item)"></p>
          </keep-alive>
          <a :href="item.Links" class="link" target="view_window">{{ item.Link }}</a>
          <p>{{ item.Mail }}</p>
      </div>
      <ComViews ref="ComViews" ></ComViews>
    </div>
    <div class="pag">
      <el-pagination
          :current-page.sync="currentPage"
          layout="prev, pager, next"
          background
          prev-text="<"
          next-text=">"
          :page-size="10"
          @current-change="pagbtn"
          :total="this.sum-10">
      </el-pagination>
    </div>
    <Footer class="Footer"></Footer>
  </div>
</template>

<script>
import Header from "@/components/pc/Header";
import Form from "@/components/pc/Input";
import Footer from "@/components/pc/Footer";
import ComViews from "@/components/pc/ComViews";

export default {
  mounted() {
    let type = this.$route.query.type;
    let data = this.$route.query.data;
    let page = this.$route.query.page;
    if (type && data && page) {
      this.$refs.Form.getin(type, data);
      this.clsaf = type;
      this.name = data;
      this.page = page;
      setTimeout(()=>{
        let pages=parseInt(page)
        this.currentPage = pages;
      },100)
      this.get_indata();
    }

  },
  data() {
    return {
      radio: '',
      inputdata: '',
      data: [],
      page: 0,
      clsaf: '',
      name: '',
      sum: 100,
      currentPage: 1,
      close_data:''
    }
  },
  components: {
    Header,
    Form,
    Footer,
    ComViews
  },
  methods: {
    FX_Get_Img(str,name){
      this.$refs.ComViews.FX_Get_Img(str,name);
    },
    Fx_In_Get(){
      this.$refs.ComViews.Fx_In_Get();
    },
    Fx_User_Get(){
      this.$refs.ComViews.Fx_User_Get();
    },
    open_password(){
      this.$refs.Header.password_open();
    },
    open_exit(){
      this.$refs.Header.dropOut_open();
    },
    open_user(){
      this.$refs.Header.user_open();
    },
    In_open(){
      this.$refs.Header.In_open();
    },
    Up_open(){
      this.$refs.Header.Up_open();
    },

    pagbtn(str) {
      this.page = str;
      this.get_indata();
    },
    get_indata() {
      this.$http.get(process.env.VUE_APP_URL + 'database/find/blurry?names=' + this.name + '&pages=' + this.page + '&clsaf=' + this.clsaf).then(str => {
        let data = str.data.setut
        let sum = str.data.sum;
        this.sum = sum
        if (data.length >= 1) {
          this.close_data='display:none';
          this.data = data;
          this.Proces_data(this.data);
          this.$router.push({
            query: {
              type: this.clsaf,
              data: this.name,
              page: this.page
            }
          }).catch(error => error);
          document.documentElement.scrollTop = 0;
        }else {
          this.close_data='display:block';
        }
      })
    },
    Researchs(str) {
      if (str._id) {
        this.$router.push({
          path: '/pc/details',
          query: {
            _id: str._id
          }
        })
      } else {
        alert('未知错误')
      }

    },
    get_data(type, data) {
      this.clsaf = type;
      this.name = data;
      this.page = 0;
      this.data = [];
      this.currentPage = 1;
      this.get_indata();
    },
    Proces_data(fsum) {
      let input = this.name;
      for (var j = 0; j < fsum.length; j++) {
        var link = fsum[j].Link;
        fsum[j].Links = link;
        if (link.length > 60) {
          var links = link.slice(0, 60);
          fsum[j].Link = links + '....';
        }
      }

      if (this.clsaf == "Research") {
        for (let i = 0; i < fsum.length; i++) {
          let str = fsum[i].Research;
          let fn = str.replace(new RegExp(input), '<span style="color:rgb(204,0,0)">' + input + '</span>');
          fsum[i].Research = fn;
        }
      } else if (this.clsaf == "Name") {
        for (let i = 0; i < fsum.length; i++) {
          let str = fsum[i].Name;
          let fn = str.replace(new RegExp(input), '<span style="color:rgb(204,0,0)">' + input + '</span>');
          fsum[i].Name = fn;
        }
      } else if (this.clsaf == "School") {
        for (let i = 0; i < fsum.length; i++) {
          let str = fsum[i].School;
          let fn = str.replace(new RegExp(input), '<span style="color:rgb(204,0,0)">' + input + '</span>');
          fsum[i].School = fn;
        }
      }
      for (let k = 0; k < fsum.length; k++) {
        let Research = fsum[k].Research;
        let Researchlenth = Research.length;
        if (Researchlenth > 60) {
          let researchsum = Research.slice(0, 60);
          fsum[k].Research = '研究领域：' + researchsum + '....';
        }
      }
      this.data = fsum;
    }
  }
}
</script>

<style scoped lang="scss">
.search {
  .data_number {
    width: 1000px;
    margin-left: 90px;
    font-size: 11px;
    color: #999;
    margin-bottom: 20px;

    span {
      color: darkred;
    }
  }

  .s_from {
    width: 1000px;
    margin-left: 90px;
    height: 60px;
    position: relative;

    .Form {
      left: 0;
    }
  }

  .data_view {
    width: 1000px;
    margin-left: 90px;
    min-height: 800px;
    position: relative;



    .close_data {
      font-size: 14px;
      color: #5f6368;
    }

    .data {
      width: 600px;
      margin-bottom: 40px;
      position: relative;
      a{
        color: #409EFF;
        font-size: 12px;
        text-decoration: none;
      }


      h3 {
        font-size: 16px;
        display: inline-block;
        font-weight: bold;
        cursor: pointer;
      }

      .information {
        width: 100%;
        min-height: 25px;
      }

      .time {
        font-size: 9px;
        display: inline-block;
        color: rgba(60, 64, 67, 0.5);
        font-weight: normal;
        float: right;
      }

      .school {
        color: rgb(60, 64, 67);
        font-size: 12px;
        display: inline-block;
        margin-left: 20px;
        font-weight: normal;
        margin-right: 10px;
      }

      .Category {
        font-size: 9px;
        display: inline-block;
        color: rgba(60, 64, 67, 0.5);
        font-weight: normal;
      }

      .information a {
        text-decoration: none;
        font-size: 14px;
        display: inline-block;
        color: rgb(26, 13, 171);

        &:visited {
          color: rgb(102, 0, 153);
        }
      }


      p {
        margin-top: 20px;
        font-size: 14px;
        margin-bottom: 10px;
        display: block;
        width: 100%;
        color: rgb(60, 64, 67);
        line-height: 30px;
        cursor: pointer;
      }
    }
  }

  .pag {
    width: 1000px;
    margin-left: 90px;
    height: 40px;
    margin-bottom: 40px;

    ::v-deep .el-pager .number {
      background-color: #203761;
      color: #ffffff;

      &:hover {
        background-color: #e4e7ed;
        color: #409EFF;
      }
    }

    ::v-deep .btn-prev {
      background-color: #203761;
      color: #ffffff;
    }

    ::v-deep .btn-next {
      background-color: #203761;
      color: #ffffff;
    }

    ::v-deep .el-icon-more {
      background-color: #203761;
      color: #ffffff;
    }

    ::v-deep .el-icon-d-arrow-right {
      background-color: #203761;
      color: #ffffff;
    }
  }

  .Footer {
    position: initial;
    width: 100%;
    height: 40px;
  }
}
</style>
