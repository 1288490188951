<template>
  <div class="From">
    <div class="input"><input v-model="form.data" @keyup.enter="btn"></div>
    <div class="button" @click="btn"></div>
    <!--        输入快捷 待API方案对接-->
    <div class="data">
      <div class="block">测试数据</div>
      <div class="block">测试数据</div>
      <div class="block">测试数据</div>
      <div class="block">测试数据</div>
      <div class="block">测试数据</div>
      <div class="block">测试数据</div>
    </div>
    <div class="type" @click="arrow_open">
      <div class="type_data" v-text="form.type"></div>
      <div class="arrow"></div>
      <div class="data_block" v-bind:class="arrow_C">
        <div class="block" @click="arrow('研究方向')">研究方向</div>
        <div class="block" @click="arrow('姓名')">姓名</div>
        <div class="block" @click="arrow('研究机构')">研究机构</div>
      </div>
    </div>
    <div class="error" v-bind:style="view.error">
      <div class="block">
        <div class="title">通知</div>
        <div class="datas" v-text="form.error"></div>
        <button class="confirm" @click="error_close">确认</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        data: '',
        type: '请选择',
        error:''
      },
      view:{
        error:''
      },
      arrow_C: ''
    }
  },
  methods: {
    error_close(){
      this.view.error='display:none';
    },
    getin(type, data) {
      this.form.type = type;
      this.form.data = data;
      this.arrow_type();
    },
    arrow_type() {
      switch (this.form.type) {
        case "研究方向":
          return "Research"
        case "姓名":
          return "Name"
        case "研究机构":
          return "School"
        case "Research":
          this.form.type = '研究方向';
          break;
        case "Name":
          this.form.type = "姓名";
          break;
        case "School":
          this.form.type = "研究机构"
          break;
      }
    },
    open_error(data){
      this.form.error=data;
      this.view.error='display:block';
    },
    btn() {
      if (this.form.type == "请选择") {
        this.open_error('未选择分类，请重试')
      } else {
        let type = this.arrow_type();
        let data = this.form.data;
        if (data == '') {
          this.open_error('搜索数据为空，请重试。')
        } else {
          this.$parent.get_data(type, data);
        }
      }
    },
    arrow_open() {
      this.arrow_C = 'open';
    },
    arrow(str) {
      this.form.type = str;
      setTimeout(() => {
        this.arrow_C = '';
      }, 10)
    }
  }
}
</script>

<style scoped lang="scss">
@mixin main_bg {
  background-size: 100% 100%;
  position: absolute;
}


.From {
  @include main_bg;
  width: 666px;
  height: 50px;


  .error{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    display: none;
    .block{
      position: absolute;
      width: 500px;
      height: 250px;
      background-color: #ffffff;
      left: 50%;
      top: 50%;
      margin-top: -125px;
      margin-left: -250px;
      border-radius: 10px;
      user-select: none;
      .title{
        position: absolute;
        background-color: #203761;
        color: #ffffff;
        text-indent: 2rem;
        top: 0;
        left: 0;
        font-size: 14px;
        font-weight: bold;
        width: 100%;
        height: 40px;
        line-height: 40px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
      }
      button {
        position: absolute;
        width: 270px;
        height: 40px;
        left: 65px;
        bottom: 80px;
        border: none;
        outline: none;
        background: none;
        background-color: #203761;
        color: #ffffff;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          background-color: #e4e7ed;
          color: #29294f;
        }
      }

      .confirm {
        width: 100px;
        left: 50%;
        margin-left: -50px;
        bottom: 20px;
        border-radius: 4px;
      }
      .datas{
        position: absolute;
        left: 0;
        bottom: 100px;
        width: 100%;
        height: 40px;
        font-size: 13px;
        text-align: center;
        line-height: 40px;
      }
    }
  }


  .input {
    position: absolute;
    width: 400px;
    height: 35px;
    left: 0;
    top: 0;
    -webkit-box-shadow: 5px 5px 5px 3px #f4f4f4;
    box-shadow: 5px 5px 5px 3px #f4f4f4;
    border-radius: 12px;

    input {
      width: 100%;
      height: 100%;
      background-color: transparent;
      outline: none;
      border: 1px solid #e4e7ed;
      border-radius: 12px;
      text-indent: 1rem;

      &:focus {
        border: 1px solid #29294f;
      }
    }
  }

  .button {
    position: absolute;
    width: 100px;
    height: 38px;
    left: 420px;
    top: 0.4px;
    background-color: #203761;
    border-radius: 10px;
    background-image: url("../../assets/image/home/Button_1.png");
    background-size: 47% 65%;
    background-repeat: no-repeat;
    background-position: 50%;
    cursor: pointer;

    &:hover {
      transition: 0.2s all;
      background-color: #ffffff;
      background-image: url("../../assets/image/home/Button_2.png");
    }
  }

  .data {
    position: absolute;
    width: 390px;
    height: 270px;
    top: 35px;
    left: 5px;
    border-radius: 15px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom: 1px solid #5f6368;
    border-left: 1px solid #5f6368;
    border-right: 1px solid #5f6368;
    display: none;

    .block {
      font-size: 14px;
      width: 100%;
      height: 45px;
      line-height: 45px;
      text-indent: 10px;
      cursor: pointer;

      &:hover {
        background-color: #e4e7ed;
      }

      &:last-child {
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
      }
    }
  }

  .type {
    width: 110px;
    height: 35px;
    position: absolute;
    left: 550px;
    top: 0;
    border-radius: 15px;
    border: 1px solid #203761;
    cursor: pointer;

    .type_data {
      position: absolute;
      left: 10px;
      top: 0;
      width: 70px;
      height: 35px;
      color: #203761;
      line-height: 35px;
      text-align: center;
      font-size: 14px;
    }

    .arrow {
      background-image: url("../../assets/image/ico/arrow.png");
      background-size: 100% 100%;
      width: 20px;
      height: 23px;
      position: absolute;
      right: 3px;
      top: 5px;
    }

    .data_block {
      position: absolute;
      left: 0;
      bottom: -141px;
      width: 100%;
      height: 135px;
      border: 1px solid #203761;
      border-radius: 15px;
      display: none;
      z-index: 2;
      .block {
        width: 100%;
        text-align: center;
        height: 45px;
        line-height: 45px;
        font-size: 14px;
        background-color: #ffffff;
        &:hover {
          background-color: #e4e7ed;
          color: rgba(42, 162, 238, 0.78);
        }

        &:first-child {
          border-top-right-radius: 15px;
          border-top-left-radius: 15px;
        }

        &:last-child {
          border-bottom-right-radius: 15px;
          border-bottom-left-radius: 15px;
        }
      }
    }

    .open {
      display: block;
    }

    .close {
      display: none;
    }

  }
}
</style>
