<template>
  <div class="ComViews">
    <div class="User_main" v-bind:style="main.In">
      <div class="img" v-bind:style="ComViewsImg"></div>
      <div class="name" v-text="Name">XXX</div>
      <div class="Personal" @click="open_user">个人信息</div>
      <div class="Password" @click="open_password">修改密码</div>
      <div class="Eeit" @click="open_exit">退出</div>
    </div>
    <div class="In_main" v-bind:style="main.User">
      <div class="Up_main" @click="In_open">登陆</div>
      <div class="In_main" @click="Up_open">注册</div>
    </div>
    <a href="http://www.3qlab.com" target="view_window">
      <div class="Img_3qlab"></div>
    </a>
    <!--    <div class="Img_ideachain"></div>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      main: {
        In: 'display:none',
        User: 'display:none',
      },
      Name: '',
      ComViewsImg: ''
    }
  },
  methods: {
    FX_Get_Img(str, name) {
      if (str) {
        this.ComViewsImg = 'background-image: url(' + process.env.VUE_APP_URL + 'image/' + str + ');';
      }
      if (name) {
        this.Name = '欢迎你归来： ' + name
      }
    },
    In_open() {
      this.$parent.In_open();
    },
    Up_open() {
      this.$parent.Up_open();
    },
    open_password() {
      this.$parent.open_password();
    },
    open_user() {
      this.$parent.open_user();
    },
    open_exit() {
      this.$parent.open_exit();
    },
    Fx_In_Get() {
      this.close();
      this.main.In = 'display:block';
    },
    Fx_User_Get() {
      this.close();
      this.main.User = 'display:block';
    },
    close() {
      this.main.In = 'display:none'
      this.main.User = 'display:none'
    }
  }
}
</script>

<style scoped lang="scss">
.ComViews {
  position: absolute;
  right: 0;
  top: 0;
  width: 350px;
  min-height: 500px;

  @mixin Port_block {
    position: absolute;
    line-height: 30px;
    background-color: #29294f;
    color: #ffffff;
    border-radius: 9px;
    cursor: pointer;
    text-align: center;
    font-size: 13px;
    width: 90px;
    height: 30px;
  }

  @mixin main {
    width: 100%;
    height: 200px;
    border: 1px solid #e4e7ed;
    position: relative;

    .img {
      position: absolute;
      width: 40px;
      height: 40px;
      left: 20px;
      top: 20px;
      background-image: url("../../assets/image/home/avatar.png");
      background-size: 130% 130%;
      background-position: center center;
      background-repeat: no-repeat;
      border-radius: 50px;
    }

    .name {
      position: absolute;
      top: 25px;
      left: 100px;
      width: 239px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      font-weight: bold;
    }


    .Personal {
      @include Port_block;
      left: 20px;
      top: 90px;

    }

    .Password {
      @include Port_block;
      left: 130px;
      top: 90px;
    }

    .Eeit {
      @include Port_block;
      left: 240px;
      top: 90px;
    }

    .In_main {
      @include Port_block;
      right: 70px;
      top: 20px;
    }

    .Up_main {
      @include Port_block;
      left: 70px;
      top: 20px;
    }
  }

  .User_main {
    @include main;
    height: 150px;
  }

  .In_main {
    @include main;
    height: 75px;
  }

  @mixin Img {
    position: relative;
    width: 100%;
    height: 150px;
    margin-top: 40px;
    background-size: 100% 100%;
  }

  .Img_3qlab {
    @include Img;
    background-image: url("../../assets/image/ComViews/3qlab.png");
  }

  .Img_ideachain {
    @include Img;
    height: 130px;
    background-image: url("../../assets/image/ComViews/ideachain.png");
  }
}
</style>
